import { Router } from 'aurelia-router';

export class UrlUtilities {
    public static setUrlParams(router: Router, baseRoute: string, params: URLSearchParams): void {
        // The current URI's search params to compare the new params against
        const existingParams = new URLSearchParams(window.location.search);
        const existingParamsSorted = [...existingParams].sort((a, b) => a[0].localeCompare(b[0]));

        // Sort params for consistency and comparing
        const newParamsSorted = [...params].sort((a, b) => a[0].localeCompare(b[0]));

        // Skip routing when the new params are the same as the current URL params
        if (JSON.stringify(existingParamsSorted) === JSON.stringify(newParamsSorted))
            return;

        // Convert params array to a simple params object the router expects
        const routeParams = {};
        for (const param of newParamsSorted)
            routeParams[param[0]] = param[1];

        router.navigateToRoute(baseRoute, routeParams);
    }
}
